import { srConfig } from '@config';
import { usePrefersReducedMotion } from '@hooks';
import sr from '@utils/sr';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }

  .success {
    color: var(--green);
    margin-bottom: 1rem;
  }

  .error {
    color: var(--error);
    margin-bottom: 1rem;
  }
`;

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const StyledForm = styled.form`
  background: var(--navy);
  padding: 2rem;
  border-radius: 4px;
  width: 90%;
  max-width: 500px;
  position: relative;

  input,
  textarea {
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.75rem;
    background: var(--light-navy);
    border: 1px solid var(--lightest-navy);
    border-radius: 4px;
    color: var(--slate);
    font-family: var(--font-mono);

    &:focus {
      outline: none;
      border-color: var(--green);
    }
  }

  textarea {
    min-height: 150px;
    resize: vertical;
  }

  button {
    ${({ theme }) => theme.mixins.smallButton};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: var(--slate);
  cursor: pointer;
  font-size: var(--fz-xl);
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState('');
  const formRef = useRef(null);

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/mlddoozk', {
        method: 'POST',
        body: new FormData(e.target),
        headers: {
          Accept: 'application/json',
        },
      });

      if (response.ok) {
        formRef.current.reset();
        setStatus('success');
        setTimeout(() => {
          setShowModal(false);
          setStatus('');
        }, 3000);
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading overline">What’s Next?</h2>

      <h2 className="title">Get In Touch</h2>

      <p>
        I’m currently looking for new opportunities, and my inbox is always open. Whether you have a
        question or just want to say hi, I’ll try my best to get back to you!
      </p>

      <button className="email-link" onClick={() => setShowModal(true)}>
        Say Hello
      </button>
      <StyledModal show={showModal}>
        <StyledForm ref={formRef} onSubmit={handleSubmit}>
          <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
          {status === 'success' ? (
            <p className="success">Message sent successfully!</p>
          ) : status === 'error' ? (
            <p className="error">Something went wrong. Please try again.</p>
          ) : null}
          <input name="name" type="text" placeholder="Name" required />
          <input name="email" type="email" placeholder="Email" required />
          <textarea name="message" placeholder="Message" required />
          <button type="submit" disabled={status === 'success'}>
            {status === 'success' ? 'Sent!' : 'Send Message'}
          </button>
        </StyledForm>
      </StyledModal>
    </StyledContactSection>
  );
};

export default Contact;
